import React from 'react';
import { TOPBRED_API_BASE_URL } from '../Utils/StringUtils';
import ClearRoundRateGraph from './ClearRoundRateGraph';
// import FirstRoundScoreGraph from './FirstRoundScoreGraph';
import ObstacleHeightGraph from './ObstacleHeightGraph';
import PrizeMoneyGraph from './PrizeMoneyGraph';

const emptyHorsePerformanceData = {
    'fei_id': '',
    'cmh_url': '',
    'horsetelex_id': '',
    'horse_name': '',
    'month': [],
    'max_obstacle_height': [],
    'sum_prize_in_euro': [],
    'mean_relative_rank_moving_avg': [],
    'mean_clear_round_moving_avg': [],
    'mean_class_clear_round_rate_moving_avg': [],
    'mean_top_10_moving_avg': [],
    'mean_class_top_10_rate_moving_avg': [],
    'mean_first_round_score_moving_avg': [],
    'mean_class_avg_first_round_score_moving_avg': []
}


function getHorsePerformanceData(feiId) {
    var url = new URL(TOPBRED_API_BASE_URL + '/horse-performance-data/')
    var params = { 'fei_id': feiId.trim() }
    url.search = new URLSearchParams(params).toString();
    console.log('url:\n' + url);
    return fetch(url)
}

export default class HorsePerformanceGraphs extends React.Component {

    constructor(props) {
        super(props);
        console.log("props:" + JSON.stringify(props))
        this.state = { horsePerformanceData: emptyHorsePerformanceData, feiId: props.feiId };
        this.horsePerformanceDataGetter = this.horsePerformanceDataGetter.bind(this);
        getHorsePerformanceData(props.feiId)
            .then(response => {
                response.json().then(response => {
                    console.log('response: ' + JSON.stringify(response));
                    let newState = {
                        ...this.state,
                        'horsePerformanceData': response
                    };
                    this.setState(newState);
                })
            }).catch(() => console.log("fetch failed :-("));
    }

    horsePerformanceDataGetter() {
        return this.state.horsePerformanceData;
    }

    render() {
        return (
            <div className='flex flex-col space-y-10 mt-2 mb-4'>
                {/* <FirstRoundScoreGraph horsePerformanceDataGetter={this.horsePerformanceDataGetter}></FirstRoundScoreGraph> */}
                <ClearRoundRateGraph horsePerformanceDataGetter={this.horsePerformanceDataGetter}></ClearRoundRateGraph>
                <ObstacleHeightGraph horsePerformanceDataGetter={this.horsePerformanceDataGetter}></ObstacleHeightGraph>
                <PrizeMoneyGraph horsePerformanceDataGetter={this.horsePerformanceDataGetter}></PrizeMoneyGraph>
                {/* <Top10RateGraph horsePerformanceDataGetter={this.horsePerformanceDataGetter}></Top10RateGraph> */}
            </div>
        );
    }

}