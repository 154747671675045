import React from 'react';
import mixpanel from 'mixpanel-browser';
import Header from './Header';
import { HiOutlineMail, HiOutlinePhone } from 'react-icons/hi';

export default class Contact extends React.Component {

    componentDidMount() {
        mixpanel.track('contact_loaded');
    }

    render() {
        return (
            <div>
                <Header />
                <div className='flex flex-col items-center pt-6 font-sans text-gray-600'>

                    <div className='text-center pb-8'>
                        <p className='text-lg font-bold'>Contact us</p>
                    </div>
                    <div className='grid grid-cols-10 w-full text-start items-center space-x-2 pb-5'>

                        <div className='col-start-3 col-end-3'><HiOutlineMail size='30px' /></div>
                        <div className='col-start-4 col-end-10 text-lg'>info@topbred.com</div>
                    </div>
                    <div className='grid grid-cols-10 w-full text-start items-center space-x-2'>
                        <div className='col-start-3 col-end-3'><HiOutlinePhone size='30px' /></div>
                        <div className='col-start-4 col-end-10 text-lg'>+972-546650164</div>
                    </div>
                </div>
            </div>
        );
    }


}